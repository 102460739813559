import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../context/ApiContext';
import { useUser } from '../context/UserContext';
import { Box, Typography, Grid } from '@mui/material';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SecurityIcon from "@mui/icons-material/Security";
import SpeedIcon from "@mui/icons-material/Speed";
import logo from '../../src/logo.png';
import ios from '../../src/assets/ios.png';
import mq from '../../src/assets/mq.png';
import visionPro from '../../src/assets/visionPro.png';
import windows from '../../src/assets/windows.png';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GroupIcon from "@mui/icons-material/Group";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

function Landing({ user, token }) {
  const navigate = useNavigate();
  const { getAllScenes, deleteScenes } = useContext(ApiContext);
  const { updateUserInfo } = useUser();

  const appLinks = [
    {
      imgSrc: ios,
      link: 'https://apps.apple.com/us/app/hypertunnel/id1483073601',
      alt: 'iOS Store',
    },
    {
      imgSrc: visionPro,
      link: 'https://apps.apple.com/us/app/hypertunnel/id1483073601?platform=vision',
      alt: 'Vision Pro Store',
    },
    {
      imgSrc: windows,
      link: 'https://www.microsoft.com/en-us/p/hypertunnel/9n10nbsjc6tm',
      alt: 'Windows Store',
    },
    {
      imgSrc: mq,
      link: 'https://www.oculus.com/experiences/quest/5729010157116924/',
      alt: 'Meta Quest Store',
    },
  ];

  const handleDownloadGuide = async () => {
    try {
      const response = await fetch('https://hypertunnel-assets-v2.s3.us-east-1.amazonaws.com/User+Guide+-+Use+Case+3+-+v1.0.pdf');

      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'User_Guide.pdf';

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh" marginBottom='100px'>
      {/* Logo */}
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <img src={logo} alt="Logo" style={{ width: 1152, height: 324 }} />
      </Box>

      {/* Tagline */}
      <Typography display="flex" justifyContent="center" variant="h4">
        Access experts through space and time.
      </Typography>

      {/* Feature Section */}
      <Box
        marginTop={6}
        marginBottom={2}
        bgcolor="rgba(173, 216, 230, 0.3)"
        padding={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="300px"
      >
        <Typography variant="h5" fontWeight={700} textAlign="center" marginTop={4}>
          Getting Started
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {/* Feature 1 */}
          <Grid item xs={12} sm={4}>
            <Box
              padding={3}
              bgcolor="#f5f5f5"
              borderRadius={2}
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <CloudDownloadIcon fontSize="large" sx={{ color: "#36368D" }} />
              <Typography fontWeight={700} variant="h6" mt={1}>
                1. Download the App
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Choose your platform below and download the app from the app store.
              </Typography>
            </Box>
          </Grid>
          {/* Feature 2 */}
          <Grid item xs={12} sm={4}>
            <Box
              padding={3}
              bgcolor="#f5f5f5"
              borderRadius={2}
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <AddCircleOutlineIcon fontSize="large" sx={{ color: "#36368D" }} />
              <Typography fontWeight={700} variant="h6" mt={1}>
                2. Create a Scene
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Create a new scene on this website or in the app.
              </Typography>
            </Box>
          </Grid>
          {/* Feature 3 */}
          <Grid item xs={12} sm={4}>
            <Box
              padding={3}
              bgcolor="#f5f5f5"
              borderRadius={2}
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <GroupIcon fontSize="large" sx={{ color: "#36368D" }} />
              <Typography fontWeight={700} variant="h6" mt={1}>
                3. Start collaborating
              </Typography>
              <Typography variant="body2" color="text.secondary">
                In the app, enter the scene with another team member.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Download Section */}
      <Box display="flex" justifyContent="center" alignItems="flex-end">
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* "Download now" Text */}
          <Typography variant="h5" fontWeight={700} marginTop={4}>
            Download now
          </Typography>
          <Typography variant="h6" marginTop={2}>
            Available on iOS, VisionOS, Meta, and Windows
          </Typography>
          {/* App Store Buttons */}
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {appLinks.map((item, index) => (
              <Grid item xs="auto" key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item.imgSrc}
                    alt={item.alt}
                    style={{
                      width: 150,
                      height: 'auto',
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Resources Section */}
      <Box
        bgcolor="rgba(173, 216, 230, 0.3)"
        paddingBottom={4}
        marginTop={8}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="300px"
      >
        <Typography variant="h5" fontWeight={700} textAlign="center" marginTop={2}>
          Resources
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <Box
              padding={3}
              bgcolor="#f5f5f5"
              borderRadius={2}
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
            >
              <ArticleOutlinedIcon fontSize="large" sx={{ color: "#36368D" }} />
              <Typography fontWeight={700} variant="h6" mt={1}>
                App User Guide
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Complete guide on all of Hypertunnel's features.
              </Typography>
              <Box
                component="a"
                href="#"
                onClick={handleDownloadGuide}
                sx={{
                  marginTop: 'auto',
                  textDecoration: 'none',
                  color: '#36368D',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Download Guide
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              padding={3}
              bgcolor="#f5f5f5"
              borderRadius={2}
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
            >
              <SupportAgentOutlinedIcon fontSize="large" sx={{ color: "#36368D" }} />
              <Typography fontWeight={700} variant="h6" mt={1}>
                Support
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Having an issue? We're here to help! Reach out with a contact below.
              </Typography>
              <a
                href="https://www.hypertunnel.com/support"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: 16, textDecoration: 'none', color: '#36368D', fontWeight: 'bold' }}
              >
                Visit Support
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Landing;