import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../context/ApiContext';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal, Button, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AssetForm from './AssetForm';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { useUser } from '../context/UserContext';
import logo from '../../src/logo.png'
import BackButton from '../components/BackButton';
import Util from '../Util/Util';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none !important',
    '& .MuiPaper-root': {
      boxShadow: 'none !important',
    },
  },
});

function Assets({user}) {
  const { getAllAssetsByScene, createThreeDArchetype, threeDArchetypeRMS, getMeshAsset, getThreeDAsset, createImageArchetype, createAudioArchetype, audioArchetypeRMS, getAudioAsset } = useContext(ApiContext);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const location = useLocation();
  const { sceneId, sceneName } = location.state;
  const { userInfo } = useUser();
  
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    fetchAssets();  // Ensure assets are re-fetched after modal closes
    setIsModalOpen(false);
  };
  
  const handleCloseMenu = () => setAnchorEl(null);
  
  const handleMenuClick = (event, assetId) => {
    setAnchorEl(event.currentTarget);
    setAsset(assetId);
    console.log(assetId);
  };
  
  const handleArchetype = async () => {
    // Your existing asset archetype logic goes here
  }

  const { handleSubmit } = useForm();

  const fetchAssets = async () => {
    try {
      const result = await getAllAssetsByScene(sceneId);
      if (result) {
        setData(result);  // Update state with the latest assets
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  // Fetch assets when component mounts or sceneId changes
  useEffect(() => {
    fetchAssets();
  }, [sceneId]);  // Re-run on sceneId change

  // This function is passed to AssetForm to trigger table update after asset is added
  const handleAssetAdded = (newAsset) => {
    setData(prevData => [...prevData, newAsset]);  // Directly add the new asset to the data state
  };

  const columns = [
    { name: "assetName", label: "Asset Name", options: { filter: true, sort: false } },
    {
      name: "objectID",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const assetType = value.split('Asset')[0];
          return assetType;
        }
      }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const assetId = tableMeta.rowData[1];
          return (
            <IconButton
              aria-label="more"
              onClick={event => handleMenuClick(event, assetId)}
            >
              <MoreVertIcon />
            </IconButton>
          );
        }
      }
    }
  ];

  return (
    <>
      <Box position="relative" display="flex" justifyContent="center" marginBottom="20px">
        <img src={logo} alt="Logo" style={{ width: 384, height: 108 }} />
      </Box>
      <Typography variant='h4' style={{ fontWeight: 500, textAlign: 'center', margin: '20px 0' }}>Scene Management</Typography>
      <Typography variant='h5' style={{ fontWeight: 400, textAlign: 'center', margin: '20px 0' }}>{sceneName}</Typography>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" padding="10px">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          style={{ backgroundColor: 'rgb(54, 54, 141)', marginBottom: '10px' }}
        >
          Register Asset
        </Button>
      </Box>
      <Box style={{ maxHeight: '600px', width: '100%', overflowY: 'auto', padding: '10px' }}>
        <MUIDataTable
          data={data}
          columns={columns}
          style={{ boxShadow: 'none' }}
          options={{
            responsive: "standard",
            filter: true,
            search: true,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: "multiple",
            customToolbarSelect: () => { },
            setTableProps: () => ({
              className: classes.root,
            }),
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleOpenModal}>Register Asset</MenuItem>
          {/* <MenuItem onClick={handleArchetype}>Place as Scene Object</MenuItem> */}
        </Menu>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div style={{ padding: '20px' }}>
            {/* Pass the handleAssetAdded function as a prop */}
            <AssetForm onSubmit={handleSubmit} onClose={handleCloseModal} sceneId={sceneId} user={user} onAssetAdded={handleAssetAdded} />
          </div>
        </Modal>
      </Box>
    </>
  );
}

export default Assets;