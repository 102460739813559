import React, { useState, useEffect, useContext } from 'react';
import AWS from 'aws-sdk';
import {
    Box,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiContext } from '../context/ApiContext';
import BackButton from './BackButton';
import Util from '../Util/Util';

AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials('AKIA4MTWMHVHVZVPPLHF', 'YoEUPy4r0N7ZnJkCxwa3mxeqsg3we5onEYtdtf7I'),
});

const cognitoISP = new AWS.CognitoIdentityServiceProvider();

const ALL_TOOLS = [
    "MobileEnvironmentManagerToolLicenseDataBucket",
    "MobileVideoRecordToolLicenseDataBucket",
    "UserManagementToolLicenseDataBucket",
    "TeleportToolLicenseDataBucket",
    "MobileMeshScanToolLicenseDataBucket",
    "LocationToolLicenseDataBucket",
    "MobileImageCameraLicenseDataBucket",
    "HyperNoteToolLicenseDataBucket",
    "HMDEnvironmentManagerToolLicenseDataBucket",
    "HMDVideoStreamBroadcastLicenseDataBucket",
    "HMDVideoStreamViewLicenseDataBucket",
    "MobileVideoStreamBroadcastLicenseDataBucket",
    "HMDAvatarToolLicenseDataBucket",
    "MobileAvatarLicenseDataBucket",
    "ShelfToolLicenseDataBucket",
    "DashboardToolLicenseDataBucket",
    "HMDMarkerToolLicenseDataBucket"
];

function Profile() {
    const navigate = useNavigate();
    const location = useLocation();
    const { getUserById, updateUser } = useContext(ApiContext);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTools, setSelectedTools] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [displayName, setDisplayName] = useState('');

    const { userId, username } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const user = await getUserById(username);
                setUserDetails(user.resource[0]);
                setSelectedTools(user.resource[0].assignedTools || []);
                setDisplayName(user.resource[0].displayName || '');
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId, username, getUserById]);

    const handleToolChange = (tool) => {
        setSelectedTools((prevSelected) => {
            if (prevSelected.includes(tool)) {
                return prevSelected.filter((t) => t !== tool);
            } else {
                return [...prevSelected, tool];
            }
        });
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDisplayNameChange = (event) => {
        setDisplayName(event.target.value);
    };

    const handleSubmit = async () => {
        const userBody = {
            objectID: userDetails.objectID,
            createTime: Util.formatDate(),
            modifyTime: '',
            deleteTime: '',
            creatorName: userDetails.creatorName,
            username: username,
            email: userDetails.email,
            assignedTools: selectedTools,
            isInternal: userDetails.isInternal,
            isAdmin: userDetails.isAdmin,
            businessAccountName: userDetails.businessAccountName,
            userType: userDetails.userType,
            displayName: displayName ? displayName : ''
        };

        try {
            await updateUser(userDetails.objectID, userBody);
            console.log("User updated successfully:", userBody);
            handleCloseModal();
        } catch (error) {
            console.error("Failed to update user:", error);
        }
    };



    return (
        <>
            <Typography variant='h4' style={{ fontWeight: 550, display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>Profile</Typography>

            <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                    style={{
                        padding: '20px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        maxWidth: '50%',
                        marginBottom: '20px',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenModal}
                        style={{ backgroundColor: 'rgb(54, 54, 141)', marginBottom: '10px' }}
                    >
                        Manage Licenses
                    </Button>
                    <TextField
                        label="Username"
                        value={username}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        label="Email"
                        value={userDetails?.email || ''}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        label="Display Name"
                        value={displayName}
                        onChange={handleDisplayNameChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <Box style={{ alignItems: 'center', justifyContent: "center", display: 'flex' }}>
                        <Button onClick={handleCloseModal} color="error">Close</Button>
                        <Button onClick={handleSubmit} color="primary">Submit</Button>
                    </Box>
                </Box>
            </Box>

            {/* Modal for Tool Selection */}
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Select Tools</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {ALL_TOOLS.map((tool) => (
                            <Grid item xs={6} key={tool}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedTools.includes(tool)}
                                            onChange={() => handleToolChange(tool)}
                                        />
                                    }
                                    label={tool}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleCloseModal} color="error">Close</Button>
                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Profile;