import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiContext } from '../context/ApiContext';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function CreateSceneModal({ onSceneAdded, user }) {  // Accepting `user` prop here
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sceneOperations, getSceneByName } = useContext(ApiContext);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      // Use the `user` prop as needed (for example, you might want to include user info when creating a scene)
      console.log('Creating scene for user:', user.username);

      await sceneOperations(data.sceneName, user.username);
      const scene = await getSceneByName(data.sceneName, '');
      onSceneAdded(scene.resource[0]);
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ backgroundColor: 'rgb(54, 54, 141)', marginBottom: '10px' }}
      >
        Create Scene
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ style: { height: 'calc(30%)' } }}>
        <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem' }}>Create a Scene</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus
              margin="dense"
              id="sceneName"
              label="Scene Name"
              type="text"
              fullWidth
              {...register('sceneName', { required: 'Please enter a scene name.' })}
              InputProps={{
                'aria-required': true,
              }}
            />
            {errors.sceneName && (
              <Typography 
                color="error" 
                variant="subtitle2" 
                style={{ marginTop: 8, textAlign: 'center' }} 
              >
                {errors.sceneName.message}
              </Typography>
            )}
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <Typography>Loading</Typography> : <Typography>Submit</Typography>}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}