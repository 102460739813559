
import React, { useState, useEffect, useContext, useRef } from 'react';
import { CognitoIdentityProviderClient, AdminCreateUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import AWS from 'aws-sdk';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Box
} from '@mui/material';
import BackButton from './BackButton';
import { ApiContext } from '../context/ApiContext';
import Util from '../Util/Util';
import { useUser } from '../context/UserContext';
import logo from '../../src/logo.png'
import { remove } from 'aws-amplify/storage';

AWS.config.update({
  region: 'us-east-1',
  credentials: new AWS.Credentials('AKIA4MTWMHVHVZVPPLHF', 'YoEUPy4r0N7ZnJkCxwa3mxeqsg3we5onEYtdtf7I')
});

const cognitoISP = new AWS.CognitoIdentityServiceProvider();

const ALL_TOOLS = [
  "MobileEnvironmentManagerToolLicenseDataBucket",
  "MobileVideoRecordToolLicenseDataBucket",
  "UserManagementToolLicenseDataBucket",
  "TeleportToolLicenseDataBucket",
  "MobileMeshScanToolLicenseDataBucket",
  "LocationToolLicenseDataBucket",
  "MobileImageCameraLicenseDataBucket",
  "HyperNoteToolLicenseDataBucket",
  "HMDEnvironmentManagerToolLicenseDataBucket",
  "HMDVideoStreamBroadcastLicenseDataBucket",
  "HMDVideoStreamViewLicenseDataBucket",
  "MobileVideoStreamBroadcastLicenseDataBucket",
  "HMDAvatarToolLicenseDataBucket",
  "MobileAvatarLicenseDataBucket",
  "ShelfToolLicenseDataBucket",
  "DashboardToolLicenseDataBucket",
  "HMDMarkerToolLicenseDataBucket"
];

function Users() {
  const { getUserById, updateUser, addLicense, deleteLicense, getAvatarLicense, updateAvatar, uploadFile, addUser, getAllUsers } = useContext(ApiContext);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isToolsModalOpen, setIsToolsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTools, setSelectedTools] = useState([]);
  const [addedTools, setAddedTools] = useState([]);
  const [removedTools, setRemovedTools] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const { roles } = useUser();
  const { userInfo } = useUser();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setEmail] = useState('');
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const { user } = useUser(); // This will provide the logged-in user
  const cognitoClient = new CognitoIdentityProviderClient({ region: "us-east-1" });
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const handleCloseMenu = () => setAnchorEl(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleOpenAddUserModal = () => {
  //   setIsAddUserModalOpen(true);
  // };

  const handleCloseAddUserModal = () => {
    setIsAddUserModalOpen(false);
    setNewUsername('');
    setEmail('');
    setDisplayName('');
    setSelectedTools([]);
  };



  const handleMenuClick = async (event, user) => {
    setAnchorEl(event.currentTarget);
    setDisplayName(user.displayName || '');
    setSelectedTools(user.assignedTools || []);
    setIsModalOpen(true);
    try {
      const userDetails = await getUserById(user.username);
      setDisplayName(userDetails.resource[0].displayName || '');
      setSelectedTools(userDetails.resource[0].assignedTools || []);
      setSelectedUser(userDetails.resource[0])
      console.log(userDetails)
      console.log(userDetails.resource[0])
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
    setDisplayName('');
    setSelectedTools([]);
  };

  const handleToolChange = (tool) => {
    setSelectedTools((prevSelected) => {
      const isCurrentlySelected = prevSelected.includes(tool);

      if (isCurrentlySelected) {
        const updatedSelected = prevSelected.filter((t) => t !== tool);

        setAddedTools((prevAdded) => prevAdded.filter((t) => t !== tool));
        if (selectedUser.assignedTools.includes(tool)) setRemovedTools((prevRemoved) => [...prevRemoved, tool]);
        return updatedSelected;
      } else {
        const updatedSelected = [...prevSelected, tool];

        if (!selectedUser.assignedTools.includes(tool)) {
          setAddedTools((prevAdded) => [...prevAdded, tool]);
        }

        if (selectedUser.assignedTools.includes(tool)) setRemovedTools((prevRemoved) => prevRemoved.filter((t) => t !== tool));
        return updatedSelected;
      }
    });
  };



  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };

  const removeBeforePeriod = (str) => {
    const parts = str.split('.');
    return parts.length > 1 ? parts[1] : str;
  }

  const handleAddUserSubmit = async () => {
    if (!newUsername || !newEmail) {
      alert("Please fill in all required fields");
      return;
    }

    setIsLoading(true); 
    const newUser = {
      username: newUsername,
      email: newEmail,
      assignedTools: ALL_TOOLS,
      businessAccountName: userData?.resource[0]?.businessAccountName || "",
      displayName: displayName || "",
      isInternal: false,
      isAdmin: false,
      userType: "standard",
    };

    try {
      const result = await addUser(newUser);
      console.log("User created:", result);

      // Fetch the updated list of users
      const businessAccountName = userData?.resource[0]?.businessAccountName || "";
      const updatedUsers = await getAllUsers("", businessAccountName);
      setData(updatedUsers?.resource || []);

      handleCloseAddUserModal();
    } catch (error) {
      console.error("Error adding user:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleSubmit = async () => {
    if (!selectedUser) return;
    const file = fileInputRef.current.files[0];
    console.log(selectedUser)
    const userBody = {
      objectID: selectedUser.objectID,
      createTime: Util.formatDate(),
      modifyTime: '',
      deleteTime: '',
      creatorName: selectedUser.creatorName,
      username: selectedUser.username,
      email: selectedUser.email,
      assignedTools: selectedTools,
      isInternal: selectedUser.isInternal,
      isAdmin: selectedUser.isAdmin,
      businessAccountName: selectedUser.businessAccountName,
      userType: selectedUser.userType,
      displayName: displayName || '',
    };

    try {
      await updateUser(selectedUser.objectID, userBody);
      for (let tool of addedTools) {
        await addLicense(selectedUser.username, tool);
      }
      for (let tool of removedTools) {
        await deleteLicense(selectedUser.username, tool);
      }
      console.log(file)
      if (file) {
        const avatarLicense = await getAvatarLicense(selectedUser.username);
        avatarLicense.resource[0].bodyConfig.customAvatarFilePath = avatarLicense.resource[0].objectID + '.' + removeBeforePeriod(file.name);
        delete avatarLicense.resource[0]._id

        // Ensure upload completes before updating the avatar
        await uploadFile(file, avatarLicense.resource[0].objectID);
        await updateAvatar(avatarLicense.resource[0].ownerUserName, avatarLicense.resource[0]);
      }
      console.log("User updated successfully:", userBody);
    } catch (error) {
      console.error("Failed to update user:", error);
    } finally {
      setAddedTools([]);
      setRemovedTools([]);
      handleCloseModal();
    }
  };

  // const listUsers = async () => {
  //   const params = {
  //     UserPoolId: 'us-east-1_KinVHOH6j',
  //   };

  //   try {
  //     const data = await cognitoISP.listUsers(params).promise();
  //     return data.Users;
  //   } catch (error) {
  //     console.error('Error listing users:', error.message);
  //     throw error;
  //   }
  // };
  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo) {
        console.log('User is not available.');
        return;
      }

      try {
        console.log('Fetching user data...');
        const userData = await getUserById(userInfo.username); // Use userInfo.username
        console.log('User Data:', userData);
        setUserData(userData)

        const businessAccountName = userData?.resource[0]?.businessAccountName || "";
        const result = await getAllUsers("", businessAccountName);
        console.log('Fetched Users:', result);

        setData(result?.resource || []);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [userInfo]); // Add userInfo as a dependency


  const columns = [
    { name: "username", label: "Name", options: { filter: true, sort: false } },
    {
      name: "email",
      label: "Email",
      options: { filter: true, sort: false }
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const user = data[tableMeta.rowIndex];
          return (
            <IconButton
              aria-label="more"
              onClick={(event) => handleMenuClick(event, user)}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  if (!roles.includes('webadmin')) {
    return <Typography>You do not have permission to view this page.</Typography>;
  }

  return (
    <>
      <Box position="relative" display="flex" justifyContent="center" marginBottom="20px">
        <img src={logo} alt="Logo" style={{ width: 384, height: 108 }} />
      </Box>
      <Typography variant='h4' style={{ fontWeight: 500, textAlign: 'center', margin: '20px 0' }}>User List</Typography>
      <div style={{ maxHeight: '600px', width: '100%', overflowY: 'auto', padding: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddUserModalOpen(true)}  // Change this line
          style={{ backgroundColor: 'rgb(54, 54, 141)', marginBottom: '10px' }}
        >
          Add User
        </Button>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{
            responsive: "standard",
            filter: true,
            search: true,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: false,
          }}
        />
      </div>

      {/* Modal for Profile Editing */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            label="Username"
            value={selectedUser?.username || ''}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
              sx: { backgroundColor: 'rgba(211, 211, 211, 0.5)' }
            }}
            InputLabelProps={{
              shrink: true,
              sx: { marginTop: '-4px' }
            }}
          />
          <TextField
            label="Email"
            value={selectedUser?.email || ''}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
              sx: { backgroundColor: 'rgba(211, 211, 211, 0.5)' }
            }}
            InputLabelProps={{
              shrink: true,
              sx: { marginTop: '-4px' }
            }}
          />
          <TextField
            label="Display Name"
            value={displayName}
            onChange={handleDisplayNameChange}
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{
              sx: { marginTop: '-4px' }
            }}
          />
          <TextField
            type="file"
            // inputProps={{ accept: fileTypes[assetType] }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Upload Avatar"
            variant="outlined"
            inputRef={fileInputRef}
            sx={{ marginTop: '12px' }}
            onChange={(e) => {
              setSelectedFile(e.target.files[0]);
            }}
          />
          <Box style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsToolsModalOpen(true)}
              style={{ marginTop: '10px', backgroundColor: 'rgb(54, 54, 141)' }}
            >
              Manage Licenses
            </Button>
          </Box>
        </DialogContent>
        <DialogActions style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <Button onClick={handleCloseModal} color="error">Close</Button>
          <Button onClick={handleSubmit} color="primary">Save Changes</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isAddUserModalOpen} onClose={handleCloseAddUserModal} maxWidth="md" fullWidth>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            label="Username"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ sx: { marginTop: '-4px' } }}
          />
          <TextField
            label="Email"
            value={newEmail}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ sx: { marginTop: '-4px' } }}
          />
          <TextField
            type="file"
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Upload Avatar"
            variant="outlined"
            sx={{ marginTop: '12px' }}
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <Button onClick={handleCloseAddUserModal} color="error">Cancel</Button>
          <Button
            color="primary"
            onClick={handleAddUserSubmit}
            disabled={isLoading}
          >
            {isLoading? "Loading..." : "Create User"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Tool Selection */}
      <Dialog open={isToolsModalOpen} onClose={() => setIsToolsModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Select Tools</DialogTitle>
        <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Typography variant="subtitle2" style={{ marginBottom: '8px', color: 'green' }}>
            Added Tools:
          </Typography>
          <Typography variant="body2" style={{ marginBottom: '16px' }}>
            {addedTools.length > 0 ? addedTools.join(', ') : 'None'}
          </Typography>
          <Typography variant="subtitle2" style={{ marginBottom: '8px', color: 'red' }}>
            Removed Tools:
          </Typography>
          <Typography variant="body2">
            {removedTools.length > 0 ? removedTools.join(', ') : 'None'}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            {ALL_TOOLS.sort().map((tool) => (
              <Grid item xs={6} key={tool}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTools.includes(tool)}
                      onChange={() => handleToolChange(tool)}
                    />
                  }
                  label={tool}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
          <Button onClick={() => setIsToolsModalOpen(false)} color="error">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Users;    